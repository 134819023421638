export default {
  apiGateway: {
      REGION: "us-west-2",
      URL: "https://rq6q71bfy4.execute-api.us-west-2.amazonaws.com/ptest"
  },
  cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_aLyUdgPHz",
      APP_CLIENT_ID: "7ifig6d5encifq6f1lngu38l8v",
      IDENTITY_POOL_ID: "us-west-2:662b27ed-0ec4-4150-a9c0-fcda3220bcac"
  }
}
